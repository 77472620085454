import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    name: "home",
    component: HomeView,
    // 重定向
    redirect: "/admin",
    meta: { title: "小热薯" },
    children: [
      {
        path: "admin",
        name: "admin",
        component: () => import("@/views/admin/index"),
        meta: { title: "小热薯-店铺管理系统" },
      },
      {
        path: "video",
        name: "video",
        component: () => import("@/views/admin/index"),
        meta: { title: "小热薯-店铺管理系统" },
      },
      {
        path: "liebian",
        name: "liebian",
        component: () => import("@/views/admin/index"),
        meta: { title: "小热薯-店铺管理系统" },
      },
      {
        path: "user",
        name: "user",
        component: () => import("@/views/admin/index"),
        meta: { title: "小热薯-店铺管理系统" },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
// 导航收尾
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  next(); //执行进入路由，如果不写就不会进入目标页
});
export default router;
