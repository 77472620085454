<template>
  <div class="index">
    <!-- <NavHead></NavHead> -->
    <div class="body">
      <Admin></Admin>
    </div>
  </div>
</template>

<script>
import NavHead from "@/components/navMenu.vue";
import Admin from "@/components/admin.vue";
export default {
  components: {
    NavHead,
    Admin,
  },
  data() {
    return {};
  },
};
</script>

<style  scoped>
.body {
  height: 100%;
  width: 100%;
}
</style>