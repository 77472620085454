console.log(process.env.VUE_APP_BASE_API);
module.exports = {
  title: "小热薯",
  // 路由前缀
  routePre: process.env.VUE_APP_BASE_API,
  // 接口请求地址
  //   apiBaseURL: VUE_APP_API_URL,
  // 路由模式，可选值为 history 或 hash
  routerMode: "history",
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,
  imgUrl: "",
};
