<template>
  <router-view />
</template>
  
  <script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>
  
