<template>
  <!-- 顶部代码 -->
  <div class="navhead">
    <div class="topImg" style="width: 98px; height: 30px">
      <img
        style="width: 100%; height: 100%"
        src="@/assets/img/logo.png"
        alt=""
      />
    </div>
    <div class="menulist">
      <div
        class="menu_box"
        v-for="(item, index) in menu"
        :key="index"
        @click="menuBtn(item, index)"
      >
        <span :class="{ active: actives === index }"> {{ item.name }}</span>
      </div>
    </div>
    <div class="login_right">
      <div class="right_text">登录/注册</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          name: "首页",
          path: "/admin",
        },
        {
          name: "视频号分销",
          path: "/video",
        },
        {
          name: "视频号裂变",
          path: "/",
        },
        {
          name: "账号矩阵",
          path: "/",
        },
      ],

      actives: 0,
    };
  },
  created() {},
  methods: {
    menuBtn(item, index) {
      // item.path;
      // this.$router.push(item.path);
      this.actives = index;
    },
  },
};
</script>

<style lang="less" scoped>
.navhead {
  width: 100%;
  height: 64px;
  background: #ebeefb;
  box-shadow: -1px 7px 10px 1px rgba(176, 189, 201, 0.11);
  display: flex;
  min-width: 1500px;
}

.topImg {
  margin-left: 19%;
  padding: 18px;
}

.menulist {
  display: flex;
  align-items: center;
  margin-left: 26%;
}
.menu_box {
  margin: 0px 25px;

  span {
    cursor: pointer;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    line-height: 60px;
  }
  .active {
    color: #327df9;
  }
}
.login_right {
  margin-left: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .right_text {
    width: 106px;
    height: 30px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #668af9;
    border: 1px solid #668af9;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
  }
}
</style>